import React from 'react'
import { Router, Redirect } from '@reach/router'
import { compact, omit, uniq, uniqBy } from 'lodash'
import moment from 'moment'
import { getCountryName } from '../services/countries'
import {
  formatDate,
  formatDatetimeSec,
  // pluralize,
  contractStatus,
  contractPartnership,
  contractCms,
  contractTuneStatus,
  articleType,
  articlePath,
  docArticlePath,
  host,
  pageUrl,
  orNull,
} from '../services/utils'
import { createResource } from '../components/resources'
import { createPingResource } from '../components/pingresources'
import { createEventResource } from '../components/events'
import { createCallbackResource } from '../components/callbacks'
import Layout from '../components/layout/Layout'
import ContractDetails from '../components/details/Contract'
import ContractsEnded from '../components/tools/ContractsEnded'
import OutboundChecker from '../components/tools/OutboundChecker'
import QueueChecker from '../components/tools/QueueChecker'
import AdminUserForm from '../components/forms/AdminUser'
import PublisherForm from '../components/forms/Publisher'
import PublisherUserForm from '../components/forms/PublisherUser'
import ContractForm from '../components/forms/Contract'
import ContractRuleForm from '../components/forms/ContractRule'
import ConversionForm from '../components/forms/Conversion'
import AdvertiserForm from '../components/forms/Advertiser'
import AdvertiserUserForm from '../components/forms/AdvertiserUser'
import ShortlinkForm from '../components/forms/Shortlink'
import ArticleForm from '../components/forms/Article'
import DocArticleForm from '../components/forms/DocArticle'
import DocCmsForm from '../components/forms/DocCms'
import ConversionRuleForm from '../components/forms/ConversionRule'
import ConversionRuleSetForm from '../components/forms/ConversionRuleSet'
import GaAccountForm from '../components/pingforms/GaAccount'
import GaAttributionForm from '../components/pingforms/GaAttribution'
// import * as AdvertiserFilter from '../components/filters/Advertiser'
import * as ContractFilter from '../components/filters/Contract'
import Settings from '../components/settings'

import '../../static/styles.css'

const AdminUsers = createResource({
  resource: 'admin_users',
  title: 'Admin User',
  listFields: {
    'Email': 'email',
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Email': 'email',
    'Alert notifications': (record) => (record.receiveAlerts ? 'Yes' : ''),
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: AdminUserForm,
})

const Outbound = createResource({
  resource: 'publishers',
  resourcePath: 'outbound',
  title: 'Outbound account',
  listFields: {
    'Name': 'name',
    'TUNE ID': 'tuneAffiliateId',
    'Outbound': (record) =>
      record.outboundAccountName || record.outboundAccountUuid,
    'Created at': (record) => formatDate(record.createdAt),
    'Partner network': (record) => (!!record.whitelabelUrl ? 'Yes' : ''),
    'Partner count': (record) =>
      orNull(
        uniq(record.contracts.map((contract) => contract.advertiserUuid)).length
      ),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Name': 'name',
    'Description': (record) => record.description,
    'Admin': (record) => (record.isAdmin ? 'Yes' : ''),
    'Whitelabel URL': 'whitelabelUrl',
    'Whitelabel master key': 'whitelabelToken',
    'Listed source': (record) => (record.isListedSource ? 'Yes' : ''),
    'Listed source attraction': (record) => record.sourceAttraction,
    'Logo': (record) => {
      if (record.logoUrl) {
        return (
          <img
            src={record.logoUrl}
            alt="Publisher logo"
            style={{ width: 120, height: 50, objectFit: 'contain' }}
          />
        )
      } else {
        return null
      }
    },
    'Partner ID': (record) => record.tuneAffiliateId,
    'Outbound ID': (record) => record.outboundAccountUuid,
    'Outbound name': (record) => record.outboundAccountName,
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
    'Users': (record) => {
      return (
        <div className="account-users-table">
          <a href={`/outbound/users/new?publisherUuid=${record.uuid}`}>
            Create new user
          </a>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {record.users?.map((user, index) => (
                <tr key={index}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td className="actions">
                    <div>
                      <a href={`/outbound/users/${user.uuid}/edit`}>Edit</a>
                    </div>
                    <div>
                      {process.env.PUBLISHER_HOST && (
                        <a
                          href={`${process.env.PUBLISHER_HOST}/log-in-as/${user.token}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Log in as
                        </a>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    },
  },
  form: PublisherForm,
})

const OutboundUser = createResource({
  resource: 'publisher_users',
  resourcePath: 'outbound',
  title: 'Outbound user',
  listFields: {},
  showFields: {},
  form: PublisherUserForm,
})

const Inbound = createResource({
  resource: 'advertisers',
  resourcePath: 'inbound',
  title: 'Inbound account',
  listFields: {
    'Name': 'name',
    'Created at': (record) => formatDate(record.createdAt),
    'Inbound client': (record) => (record.isClient ? 'Yes' : ''),
    'Whitelabels': (record) =>
      orNull(
        uniq(
          record.contracts
            .filter((contract) => !!contract.publisher?.whitelabelUrl)
            .map((contract) => contract.publisher?.uuid)
        ).length
      ),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Email': 'email',
    'Receive notifications': (record) =>
      record.receiveNotifications ? 'Yes' : '',
    'Has inbound license': (record) => (record.isClient ? 'Yes' : ''),
    'Whitelabels': (record) => {
      const publishers = uniqBy(
        record.contracts
          .filter((contract) => !!contract.publisher?.whitelabelUrl)
          .map((contract) => contract.publisher),
        (publisher) => publisher.uuid
      )
      if (publishers?.length) {
        return (
          <>
            {publishers.map((publisher) => (
              <div key={publisher.uuid}>
                <a href={`https://${host(publisher.whitelabelUrl)}`}>
                  {host(publisher.whitelabelUrl)}
                </a>
              </div>
            ))}
          </>
        )
      } else {
        return null
      }
    },
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
    'Users': (record) => {
      return (
        <div className="account-users-table">
          <a href={`/inbound/users/new?advertiserUuid=${record.uuid}`}>
            Create new user
          </a>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {record.users?.map((user, index) => (
                <tr key={index}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td className="actions">
                    <div>
                      <a href={`/inbound/users/${user.uuid}/edit`}>Edit</a>
                    </div>
                    <div>
                      {process.env.CONNECT_HOST && (
                        <div>
                          <a
                            href={`${process.env.CONNECT_HOST}/log-in-as/${user.token}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Log in as
                          </a>
                        </div>
                      )}
                      {uniqBy(
                        record.contracts
                          .filter(
                            (contract) => !!contract.publisher?.whitelabelUrl
                          )
                          .map((contract) => contract.publisher),
                        (publisher) => publisher.uuid
                      )?.map((publisher) => (
                        <div key={publisher.uuid}>
                          <a
                            href={`https://${host(
                              publisher.whitelabelUrl
                            )}/log-in-as/${user.token}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Log in as ({host(publisher.whitelabelUrl)})
                          </a>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    },
  },
  form: AdvertiserForm,
})

const InboundUser = createResource({
  resource: 'advertiser_users',
  resourcePath: 'inbound',
  title: 'Inbound user',
  listFields: {},
  showFields: {},
  form: AdvertiserUserForm,
})

// const Publishers = createResource({
//   resource: 'publishers',
//   title: 'Publisher',
//   listFields: {
//     'Name': 'name',
//     'TUNE ID': 'tuneAffiliateId',
//     'Admin': (record) => (record.isAdmin ? 'Admin' : ''),
//     'Created at': (record) => formatDate(record.createdAt),
//   },
//   showFields: {
//     'Internal ID': 'uuid',
//     'Name': 'name',
//     'Description': (record) => record.description,
//     'Admin': (record) => (record.isAdmin ? 'Yes' : ''),
//     'Listed source': (record) => (record.isListedSource ? 'Yes' : ''),
//     'Listed source attraction': (record) => record.sourceAttraction,
//     'Logo': (record) => {
//       if (record.logoUrl) {
//         return (
//           <img
//             src={record.logoUrl}
//             alt="Publisher logo"
//             style={{ width: 120, height: 50, objectFit: 'contain' }}
//           />
//         )
//       } else {
//         return null
//       }
//     },
//     'Users': (record) => pluralize(record.users.length, 'user'),
//     'Partner ID': (record) => record.tuneAffiliateId,
//     'Created at': (record) => formatDate(record.createdAt),
//     'Updated at': (record) => formatDate(record.updatedAt),
//   },
//   form: PublisherForm,
// })
//
// const PublisherUsers = createResource({
//   resource: 'publisher_users',
//   title: 'Publisher User',
//   listFields: {
//     'Publisher': (record) => record.publisher?.name || 'No publisher',
//     'Name': 'name',
//     'Email': 'email',
//     'Created at': (record) => formatDate(record.createdAt),
//   },
//   showFields: {
//     'Internal ID': 'uuid',
//     'Publisher': (record) => record.publisher?.name || 'No publisher',
//     'Name': 'name',
//     'Email': 'email',
//     'Created at': (record) => formatDate(record.createdAt),
//     'Updated at': (record) => formatDate(record.updatedAt),
//   },
//   form: PublisherUserForm,
// })

// const Advertisers = createResource({
//   resource: 'advertisers',
//   title: 'Advertiser',
//   listFields: {
//     'Domains': (record) =>
//       uniq(record.contracts?.map((contract) => contract.domain)).join(', '),
//     'Name': 'name',
//     'TUNE ID': 'tuneAdvertiserId',
//     'Created at': (record) => formatDate(record.createdAt),
//   },
//   showFields: {
//     'Internal ID': 'uuid',
//     'Advertiser TUNE ID': 'tuneAdvertiserId',
//     'API2CART store key': 'a2cStoreKey',
//     'API2CART webhook': (record) => (record.a2cWebhookStatus ? 'Set' : ''),
//     'API2CART URL': 'a2cUrl',
//     'API2CART CMS': 'a2cCms',
//     'Name': 'name',
//     'Company info': (record) => JSON.stringify(record.company),
//     'Billing info': (record) => JSON.stringify(record.billing),
//     'Users': (record) => pluralize(record.users.length, 'user'),
//     'Created at': (record) => formatDate(record.createdAt),
//     'Updated at': (record) => formatDate(record.updatedAt),
//   },
//   form: AdvertiserForm,
//   filter: AdvertiserFilter,
// })
//
// const AdvertiserUsers = createResource({
//   resource: 'advertiser_users',
//   title: 'Advertiser User',
//   listFields: {
//     'Advertiser': (record) => record.advertiser?.name || 'No advertiser',
//     'Name': 'name',
//     'Email': 'email',
//     'Created at': (record) => formatDate(record.createdAt),
//   },
//   showFields: {
//     'Internal ID': 'uuid',
//     'Advertiser': (record) => record.advertiser?.name || 'No advertiser',
//     'Name': 'name',
//     'Email': 'email',
//     'Created at': (record) => formatDate(record.createdAt),
//     'Updated at': (record) => formatDate(record.updatedAt),
//   },
//   form: AdvertiserUserForm,
// })

const Contracts = createResource({
  resource: 'contracts',
  title: 'Campaign',
  listFields: {
    'Name': 'code',
    'Publisher': (record) => record.publisher?.name,
    'Advertiser': (record) => record.advertiser?.name,
    'Status': (record) => contractStatus(record),
    'TUNE ID': 'tuneOfferId',
    'Tune status': (record) => contractTuneStatus(record),
    'Contact Name': (record) => record.data.contactName,
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Advertiser code': 'code',
    'Advertiser URL': 'url',
    'Advertiser domain': 'domain',
    'Campaign smart link': (record) => {
      const url = `${process.env.BACKEND_HOST}/connect/v1/shortlink/${record.shortlinkCode}`
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )
    },
    'Campaign tracking url': 'trackingUrl',
    'Status': (record) => contractStatus(record, true),
    'Publisher': (record) => (
      <a href={`/outbound/${record.publisher?.uuid}`}>
        {record.publisher?.name}
      </a>
    ),
    'Advertiser': (record) => (
      <a href={`/inbound/${record.advertiser?.uuid}`}>
        {record.advertiser?.name}
      </a>
    ),
    'Owner': (record) => (
      <a href={`/publisher_users/${record.user?.uuid}`}>{record.user?.name}</a>
    ),
    'Publisher access': (record) =>
      record.publishers?.map((publisher) => (
        <a key={publisher.uuid} href={`/outbound/${publisher.uuid}`}>
          {publisher.name}
        </a>
      )) || 'Owner only',
    'Advertiser access': (record) =>
      record.advertisers?.map((advertiser) => (
        <a key={advertiser.uuid} href={`/inbound/${advertiser.uuid}`}>
          {advertiser.name}
        </a>
      )) || 'Owner only',

    '!Links': '',
    'Agreement PDF': (record) => {
      const url = `${
        process.env.BACKEND_HOST
      }/connect/v1/contract/${encodeURIComponent(record.code)}.pdf`
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )
    },
    'Connect Signup': (record) => {
      const url = `${process.env.CONNECT_HOST}/signup?code=${encodeURIComponent(
        record.code
      )}`
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )
    },
    'Connect CMS install': (record) => {
      const url = `${
        process.env.CONNECT_HOST
      }/signup/platforms?code=${encodeURIComponent(record.code)}`
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )
    },

    '!Company': '',
    'Company name': (record) => record.data.companyName,
    'Company email': (record) => record.data.companyEmail,
    'Company address': (record) =>
      compact([
        record.data.companyAddress,
        record.data.companyZip,
        record.data.companyCity,
        getCountryName(record.data.companyCountry),
      ]).join(', '),
    'Company VAT': (record) => record.data.companyVat,
    'Same billing address': (record) =>
      record.data.sameBillingAddress ? 'Yes' : 'No',

    '!Billing': '',
    'Billing name': (record) => record.data.billingName,
    'Billing email': (record) => record.data.billingEmail,
    'Billing address': (record) =>
      compact([
        record.data.billingAddress,
        record.data.billingZip,
        record.data.billingCity,
        getCountryName(record.data.billingCountry),
      ]).join(', '),

    '!Contact person': '',
    'Contact name': (record) => record.data.contactName,
    'Contact position': (record) => record.data.contactPosition,
    'Contact phone': (record) => record.data.contactPhone,
    'Contact email': (record) => record.data.contactEmail,
    'Signature': (record) => record.data.signature,
    'Signature IP': (record) => record.data.contactIp,

    '!Manager': '',
    'Manager name': (record) => record.data.managerName,
    'Manager position': (record) => record.data.managerPosition,
    'Manager email': (record) => record.data.managerEmail,
    'Manager IP': (record) => record.data.managerIp,

    '!Choices': '',
    'Partnership': (record) => contractPartnership(record.data.partnership),
    'CMS': (record) => contractCms(record.data.cms, record.data.cmsCustom),
    'Have accepted terms': (record) => (record.data.acceptTerms ? 'Yes' : 'No'),
    'Have finished form': (record) => (record.formFinished ? 'Yes' : 'No'),

    '!Contract details': '',
    'Contract length': (record) => formatDate(record.data.contractLength),
    'Initial fee': (record) =>
      `${record.data.currency} ${record.data.initialFee}`,
    'Monthly fee': (record) =>
      `${record.data.currency} ${record.data.monthlyFee}`,
    'Invoice period': (record) =>
      `${record.data.invoicePeriod} ${record.data.invoicePeriodType}`,
    'Commission model': (record) => record.data.commissionModel,
    'Commission percentage': (record) => `${record.data.commissionPercentage}%`,
    'Commission price': (record) =>
      `${record.data.currency} ${record.data.commissionPrice}`,
    'Commission renegotiation period': (record) =>
      `${record.data.commissionRenegotiationPeriod} ${record.data.commissionRenegotiationPeriodType}`,
    'Transaction registration period': (record) =>
      `${record.data.transactionRegistrationPeriod} ${record.data.transactionRegistrationPeriodType}`,
    'Transaction decline period': (record) =>
      `${record.data.transactionDeclinePeriod} ${record.data.transactionDeclinePeriodType}`,
    'Tracking period': (record) =>
      `${record.data.trackingPeriod} ${record.data.trackingPeriodType}`,
    'Tracking implementation': (record) => record.data.trackingImplementation,
    'Tracking installed': (record) =>
      record.data.trackingImplemented ? 'Yes' : '',

    '!Tune': '',
    'Tune status': (record) => contractTuneStatus(record),
    'Advertiser ID': 'tuneAdvertiserId',
    'Offer ID': 'tuneOfferId',
    'Current TUNE advertiser record': (record) => {
      if (record.data.tuneAdvertiser) {
        return <pre>{JSON.stringify(record.data.tuneAdvertiser, null, 2)}</pre>
      }
    },
    'Current TUNE offer record': (record) => {
      if (record.data.tuneOffer) {
        return <pre>{JSON.stringify(record.data.tuneOffer, null, 2)}</pre>
      }
    },

    '!Attribution Model': '',
    'Rules': (record) => {
      return (
        <div>
          {record.conversionRules?.map((conversionRule, index) => (
            <div key={index}>
              <a
                href={`/attributions/${conversionRule.uuid}`}
                target="_blank"
                rel="noreferrer"
              >
                {conversionRule.name}
              </a>
            </div>
          ))}
        </div>
      )
    },

    '_0': '',
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  details: ContractDetails,
  filter: ContractFilter,
  form: ContractForm,
})

const Shortlinks = createResource({
  resource: 'shortlinks',
  title: 'Smart Link',
  listFields: {
    'Name': 'name',
    'Campaign': (record) => record.contract.code,
    'Target': 'targetUrl',
    'Link': 'trackingUrl',
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Campaign': (record) => record.contract.code,
    'Name': 'name',
    'Shortlink': (record) => {
      const url = `${process.env.BACKEND_HOST}/connect/v1/shortlink/${record.code}`
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )
    },
    'Target': (record) => (
      <a href={record.targetUrl} target="_blank" rel="noreferrer">
        {record.targetUrl}
      </a>
    ),
    'Link': (record) => (
      <a href={record.trackingUrl} target="_blank" rel="noreferrer">
        {record.trackingUrl}
      </a>
    ),
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: ShortlinkForm,
})

const Articles = createResource({
  resource: 'articles',
  title: 'Help Article',
  listFields: {
    'Published': (record) => (record.isPublished ? 'Yes' : ''),
    'Category': (record) => articleType(record),
    'Slug': 'slug',
    'Headline': 'title',
    'Last update at': (record) => formatDate(record.updatedAt),
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Published': (record) => (record.isPublished ? 'Yes' : ''),
    'Category': (record) => articleType(record),
    'Slug': 'slug',
    'Path': (record) => articlePath(record),
    'Logo': (record) => {
      if (record.logoUrl) {
        return (
          <img
            src={record.logoUrl}
            alt="Major CMS logo"
            style={{ width: 120, height: 120, objectFit: 'contain' }}
          />
        )
      } else {
        return null
      }
    },
    'Headline': 'title',
    'Subheadline': 'subtitle',
    'Body': (record) => (
      <div
        className="article-preview"
        dangerouslySetInnerHTML={{ __html: record.body }}
      />
    ),
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: ArticleForm,
})

const DocArticles = createResource({
  resource: 'doc_articles',
  title: 'Docs Article',
  listFields: {
    'Published': (record) => (record.isPublished ? 'Yes' : ''),
    'Popular': (record) => (record.isPopular ? 'Yes' : ''),
    'Category': (record) => {
      if (record.category) {
        return `${record.group} / ${record.category}`
      } else {
        return record.group
      }
    },
    'Slug': 'slug',
    'Title': 'shortTitle',
    'Last update at': (record) => formatDate(record.updatedAt),
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Published': (record) => (record.isPublished ? 'Yes' : ''),
    'Popular': (record) => (record.isPopular ? 'Yes' : ''),
    'Main category': 'group',
    'Category': 'category',
    'Slug': 'slug',
    'Path': (record) => docArticlePath(record),
    'Short title': 'shortTitle',
    'Title': 'title',
    'Body': (record) => (
      <div
        className="doc-article-preview"
        dangerouslySetInnerHTML={{ __html: record.body }}
      />
    ),
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: DocArticleForm,
})

const DocCms = createResource({
  resource: 'doc_cms',
  resourcePath: 'onboard/cms',
  title: 'Onboard CMS',
  listFields: {
    'Active': (record) => (record.isActive ? 'Yes' : ''),
    'Popular': (record) => (record.isPopular ? 'Yes' : ''),
    'Name': 'slug',
    'Last update at': (record) => formatDate(record.updatedAt),
    'Created at': (record) => formatDate(record.createdAt),
  },
  showFields: {
    'Internal ID': 'uuid',
    'Active': (record) => (record.isActive ? 'Yes' : ''),
    'Popular': (record) => (record.isPopular ? 'Yes' : ''),
    'Name': 'name',
    'Article': (record) => {
      if (record.docArticleUuid) {
        return <a href={`/doc_articles/${record.docArticleUuid}`}>Article</a>
      } else {
        return null
      }
    },
    'Logo': (record) => {
      if (record.logoUrl) {
        return (
          <img
            src={record.logoUrl}
            alt="CMS logo"
            style={{ width: 120, height: 120, objectFit: 'contain' }}
          />
        )
      } else {
        return null
      }
    },
    'Created at': (record) => formatDate(record.createdAt),
    'Updated at': (record) => formatDate(record.updatedAt),
  },
  form: DocCmsForm,
})

const ContractRules = createResource({
  resource: 'contract_rules',
  resourcePath: 'rules',
  title: 'Campaign Rule',
  listFields: {
    'Advertiser': (record) => record.advertiser?.name,
    'Contract': (record) => record.contract?.code,
    'Name': 'name',
    'Active': (record) => (record.isActive ? 'Yes' : ''),
  },
  showFields: {
    'Contract': (record) =>
      record.contractUuid ? (
        <a
          href={`/contracts/${record.contractUuid}`}
          target="_blank"
          rel="noreferrer"
        >
          {record.contract?.code}
        </a>
      ) : (
        ''
      ),
    'Advertiser': (record) =>
      record.advertiserUuid ? (
        <a
          href={`/inbound/${record.advertiserUuid}`}
          target="_blank"
          rel="noreferrer"
        >
          {record.advertiser?.name}
        </a>
      ) : (
        ''
      ),
    'Publisher': (record) =>
      record.publisher ? (
        <a
          href={`/outbound/${record.publisher.uuid}`}
          target="_blank"
          rel="noreferrer"
        >
          {record.publisher?.name}
        </a>
      ) : (
        ''
      ),
    'Active': (record) => (record.isActive ? 'Yes' : ''),
    'Name': 'name',
    'Rule': (record) =>
      `IF ${[record.triggerKey, record.triggerRule, record.triggerValue].join(
        ' '
      )} THEN ${[record.actionKey, record.actionRule, record.actionValue].join(
        ' '
      )}`,
  },
  form: ContractRuleForm,
})

const ConversionRules = createResource({
  resource: 'conversion_rules',
  resourcePath: 'attributions',
  title: 'Attribution Rule',
  listFields: {
    'Advertiser': (record) =>
      record.isDefault ? 'Global rule' : record.advertiser?.name,
    'Contract': (record) => (record.isDefault ? '—' : record.contract?.code),
    'Name': 'name',
    'Active': (record) => (record.isActive ? 'Yes' : ''),
  },
  showFields: {
    'Advertiser': (record) =>
      record.isDefault ? (
        'Global rule'
      ) : record.advertiserUuid ? (
        <a
          href={`/inbound/${record.advertiserUuid}`}
          target="_blank"
          rel="noreferrer"
        >
          {record.advertiser?.name}
        </a>
      ) : (
        ''
      ),
    'Contract': (record) =>
      record.isDefault ? (
        ''
      ) : record.contractUuid ? (
        <a
          href={`/contracts/${record.contractUuid}`}
          target="_blank"
          rel="noreferrer"
        >
          {record.contract?.code}
        </a>
      ) : (
        ''
      ),
    'Active': (record) => (record.isActive ? 'Yes' : ''),
    'Global': (record) => (record.isDefault ? 'Yes' : ''),
    'Name': 'name',
    'Rule': (record) => {
      if (record.rule) {
        return <pre>{JSON.stringify(record.rule, null, 2)}</pre>
      }
    },
  },
  form: ConversionRuleForm,
})

const ConversionRuleSets = createResource({
  resource: 'conversion_rule_sets',
  resourcePath: 'attribution_presets',
  title: 'Attribution Preset',
  listFields: {
    'Name': 'name',
    'Icon': 'abbr',
    'Active': (record) => (record.isActive ? 'Yes' : ''),
    'Rules': (record) => record.rules.length,
  },
  showFields: {
    'Name': 'name',
    'Active': (record) => (record.isActive ? 'Yes' : ''),
    'Icon': 'abbr',
    'Description': 'description',
    'Rules': (record) =>
      record.rules.map((rule) => (
        <div key={rule.uuid}>
          <a
            href={`/attributions/${rule.uuid}`}
            target="_blank"
            rel="noreferrer"
          >
            {rule.name}
          </a>
        </div>
      )),
  },
  form: ConversionRuleSetForm,
})

const Callbacks = createCallbackResource({
  resource: 'callbacks',
  resourceKey: 'callbackUuid',
  resourcePath: 'conversions',
  title: 'Conversion',
  listFields: {
    'Domain': (record) => {
      if (record.callbackMetaDomain) {
        return host(record.callbackMetaDomain)
      }
    },
    'Advertiser': (record) => record.advertiser?.name,
    'HLTID': (record) => {
      const hltid = record.callbackHltidless
        ? 'hltidless'
        : record.callbackHltid
      if (record.callbackIsBad || record.callbackConversionError) {
        return `⚠️${hltid}`
      } else {
        return hltid
      }
    },
    'Offer ID': 'callbackOfferId',
    'Order ID': 'callbackOrderId',
    'Sub total': (record) =>
      `${record.callbackOrderCurrencyCode || 'DKK'} ${
        record.callbackOrderSubTotal || record.calculatedSubTotal
      }`,
    'Status': 'callbackMetaStatus',
    'Created at': (record) => formatDatetimeSec(record.callbackCreatedDatetime),
  },
  showFields: {
    'UUID': 'callbackUuid',
    'HLTID': (record) => {
      if (record.callbackIsBad || record.callbackConversionError) {
        return `⚠️${record.callbackHltid}`
      } else {
        return record.callbackHltid
      }
    },
    'Offer ID': 'callbackOfferId',
    'Order ID': 'callbackOrderId',
    'Reference ID': 'callbackReferenceId',
    'Coupon code': 'callbackOrderCouponCode',
    'Secret key': 'callbackSecretKey',
    'Currency': 'callbackOrderCurrencyCode',
    'Sub total': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderSubTotal}`,
    'Total': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderTotal}`,
    'Tax': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderTax}`,
    'Tax rate': (record) => `${record.callbackOrderTaxRate}`,
    'Shipping': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderShipping}`,
    'Shipping no tax': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderShippingNoTax}`,
    'Calculated sub total': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.calculatedSubTotal}`,
    'Discount': (record) =>
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderDiscount}`,
    'Payout override': (record) =>
      !!record.callbackOrderPayout &&
      `${record.callbackOrderCurrencyCode} ${record.callbackOrderPayout}`,
    'Goal': (record) =>
      compact([record.callbackGoalId], [record.callbackGoalName]).join(' - '),
    'Marketing tags': (record) => {
      if (record.callbackMarketingTags) {
        return (
          <pre>{JSON.stringify(record.callbackMarketingTags, null, 2)}</pre>
        )
      }
    },
    'Items': (record) => {
      if (record.callbackOrderItems) {
        return <pre>{JSON.stringify(record.callbackOrderItems, null, 2)}</pre>
      }
    },
    'Created at': (record) => formatDatetimeSec(record.callbackCreatedDatetime),
    'Meta IP': 'callbackMetaIp',
    'Meta URL': 'callbackMetaUrl',
    'Meta UA': 'callbackMetaUserAgent',
    'Meta domain': 'callbackMetaDomain',
    'Meta conversion URL': 'callbackMetaConversionUrl',
    'Meta status': 'callbackMetaStatus',
    'Meta status info': (record) => {
      if (record.callbackMetaStatusMessage) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: record.callbackMetaStatusMessage.replace(
                /\((.+-.+-.+-.+)\)/,
                "(<a href='/conversions/$1'>$1</a>)"
              ),
            }}
          />
        )
      }
    },
    'Meta API2CART status': 'callbackMetaA2cStatus',
    'Conversion message': 'callbackConversionError',
    'Conversion details': (record) => {
      return (
        <div>
          <div>
            {record.callbackCreateConversionReport && (
              <pre>
                {JSON.stringify(record.callbackCreateConversionReport, null, 2)}
              </pre>
            )}
          </div>
          <div>
            {record.callbackUpdateConversionReport && (
              <pre>
                {JSON.stringify(record.callbackUpdateConversionReport, null, 2)}
              </pre>
            )}
          </div>
        </div>
      )
    },
  },
  form: ConversionForm,
})

const CallbackEvents = createEventResource({
  resource: 'conversion_events',
  resourceKey: 'eventUuid',
  title: 'Event Callback',
  listFields: {
    'Domain': (record) => {
      if (record.events?.length) {
        const domains = record.events?.map((event) => host(event.pageUrl || ''))
        return uniq(domains).join(', ')
      }
      return ''
    },
    'UUID': 'eventUuid',
    'HLTID': (record) => {
      if (record.meta?.conversionError) {
        return `⚠️${record.hltid}`
      } else {
        return record.hltid
      }
    },
    'Status': (record) => (record.meta?.conversionAt ? 'Converted' : ''),
    'Created at': (record) => formatDatetimeSec(record.eventCreatedAt),
    'Updated at': (record) => formatDatetimeSec(record.eventUpdatedAt),
  },
  showFields: {
    'UUID': (record) => (
      <div>
        {record.eventUuid}{' '}
        {record.hltid && record.meta?.conversionAt && (
          <small>
            (<a href={`/transactions/${record.hltid}`}>transaction</a>{' '}
            <a href={`/conversions/${record.hltid}`}>conversion</a>)
          </small>
        )}
      </div>
    ),
    'Events': (record) => {
      if (record.events?.length) {
        let prevPageUrl = ''
        let prevTimestamp = ''
        return record.events.map((event, index) => {
          const showPage =
            prevPageUrl !== event.pageUrl && prevTimestamp !== event.timestamp
          prevPageUrl = event.pageUrl
          prevTimestamp = event.timestamp

          return (
            <div key={index} className={`event-event ${showPage ? 'mt' : ''}`}>
              {showPage && (
                <div>
                  <small title={event.pageUrl}>{pageUrl(event.pageUrl)}</small>
                  <small>
                    {moment(event.timestamp).format('DD.MM.YYYY HH:mm:ss')}
                  </small>
                </div>
              )}
              <div>
                <span title="Type">{event.type}</span> —{' '}
                <span title="Key">{event.key}</span> —{' '}
                <span title="Value">{event.value}</span>
              </div>
            </div>
          )
        })
      }
    },
    'Custom events': (record) => {
      if (record.customEvents?.length) {
        let prevPageUrl = ''
        let prevTimestamp = ''
        return record.customEvents.map((event, index) => {
          const showPage =
            prevPageUrl !== event.pageUrl && prevTimestamp !== event.timestamp
          prevPageUrl = event.pageUrl
          prevTimestamp = event.timestamp

          return (
            <div key={index} className={`event-event ${showPage ? 'mt' : ''}`}>
              {showPage && (
                <div>
                  <small title={event.pageUrl}>{pageUrl(event.pageUrl)}</small>
                  <small>
                    {moment(event.timestamp).format('DD.MM.YYYY HH:mm:ss')}
                  </small>
                </div>
              )}
              <div>
                <span title="Type">{event.type}</span> —{' '}
                <span title="Key">{event.key}</span> —{' '}
                <span title="Value">{event.value}</span>
              </div>
            </div>
          )
        })
      }
    },
    'Content': (record) => {
      return (
        <pre>
          {JSON.stringify(
            omit(record, [
              'events',
              'customEvents',
              'meta',
              'marketingTags',
              'eventUuid',
              'eventCreatedAt',
              'eventUpdatedAt',
            ]),
            null,
            2
          )}
        </pre>
      )
    },
    'Marketing tags': (record) => {
      if (record.marketingTags?.length) {
        return <pre>{JSON.stringify(record.marketingTags, null, 2)}</pre>
      }
    },
    'Meta': (record) => {
      if (record.meta) {
        return <pre>{JSON.stringify(record.meta, null, 2)}</pre>
      }
    },
    'Created at': (record) => formatDatetimeSec(record.eventCreatedAt),
    'Updated at': (record) => formatDatetimeSec(record.eventUpdatedAt),
  },
})

const Invoices = createResource({
  resource: 'invoices',
  title: 'Connect Invoice',
  listFields: {
    'Advertiser': (record) => record.advertiser.name,
    'Contract': (record) => record.contract.code,
    'Period': 'period',
  },
  showFields: {
    'UUID': 'uuid',
    'Hash': 'hash',
    'Contract': (record) => record.contract.code,
    'Part path': (record) =>
      `heylinkapi.com/connect/v1/invoice/${record.advertiser.uuid}/${record.uuid}.pdf`,
    'Full path': (record) =>
      `heylinkapi.com/connect/v1/invoice/${
        record.advertiser.uuid
      }/${encodeURIComponent(record.period)}.pdf`,
    'Type': 'type',
    'Status': 'status',
    'Sent?': (record) => (record.isSent ? 'Yes' : ''),
    'Paid?': (record) => (record.isPaid ? 'Yes' : ''),
    'Data': (record) => {
      if (record.data) {
        return (
          <pre style={{ maxHeight: 300, overflow: 'auto' }}>
            {JSON.stringify(record.data, null, 2)}
          </pre>
        )
      }
    },
    'Created at': (record) => formatDatetimeSec(record.eventCreatedAt),
    'Updated at': (record) => formatDatetimeSec(record.eventUpdatedAt),
  },
})

const GaAccounts = createPingResource({
  resource: 'ga_accounts',
  title: 'GA Account',
  listFields: {
    'Domain': 'domain',
    'Measurement ID': 'measurementId',
  },
  showFields: {
    'UUID': 'uuid',
    'Domain': 'domain',
    'Measurement ID': 'measurementId',
    'API Secret': 'apiSecret',
    'Created at': (record) => formatDatetimeSec(record.createdAt),
  },
  form: GaAccountForm,
})

const GaAttributions = createPingResource({
  resource: 'ga_attributions',
  title: 'GA Attribution',
  listFields: {
    'URL Param': 'param',
    'UTM Source': 'utmSource',
    'UTM Medium': 'utmMedium',
  },
  showFields: {
    'UUID': 'uuid',
    'URL Param': 'param',
    'UTM Source': 'utmSource',
    'UTM Medium': 'utmMedium',
    'Additional': 'utmCustom',
    'Created at': (record) => formatDatetimeSec(record.createdAt),
  },
  form: GaAttributionForm,
})

export default function IndexPage(props) {
  return (
    <Layout>
      <Router>
        <Redirect from="/" to="/contracts" noThrow={true} />

        <AdminUsers.List path="/admin_users" />
        <AdminUsers.Show path="/admin_users/:uuid" />
        <AdminUsers.New path="/admin_users/new" />
        <AdminUsers.Edit path="/admin_users/:uuid/edit" />

        <Outbound.List path="/outbound" />
        <Outbound.Show path="/outbound/:uuid" />
        <Outbound.New path="/outbound/new" />
        <Outbound.Edit path="/outbound/:uuid/edit" />
        <OutboundUser.List path="/outbound/users" />
        <OutboundUser.New path="/outbound/users/new" />
        <OutboundUser.Edit path="/outbound/users/:uuid/edit" />

        <Inbound.List path="/inbound" />
        <Inbound.Show path="/inbound/:uuid" />
        <Inbound.New path="/inbound/new" />
        <Inbound.Edit path="/inbound/:uuid/edit" />
        <InboundUser.List path="/inbound/users" />
        <InboundUser.New path="/inbound/users/new" />
        <InboundUser.Edit path="/inbound/users/:uuid/edit" />

        <Contracts.List path="/contracts" />
        <Contracts.Show path="/contracts/:uuid" />
        <Contracts.New path="/contracts/new" />
        <Contracts.Edit path="/contracts/:uuid/edit" />

        <Shortlinks.List path="/shortlinks" />
        <Shortlinks.Show path="/shortlinks/:uuid" />
        <Shortlinks.New path="/shortlinks/new" />
        <Shortlinks.Edit path="/shortlinks/:uuid/edit" />

        <Articles.List path="/articles" />
        <Articles.Show path="/articles/:uuid" />
        <Articles.New path="/articles/new" />
        <Articles.Edit path="/articles/:uuid/edit" />

        <DocArticles.List path="/doc_articles" />
        <DocArticles.Show path="/doc_articles/:uuid" />
        <DocArticles.New path="/doc_articles/new" />
        <DocArticles.Edit path="/doc_articles/:uuid/edit" />

        <DocCms.List path="/onboard/cms" />
        <DocCms.Show path="/onboard/cms/:uuid" />
        <DocCms.New path="/onboard/cms/new" />
        <DocCms.Edit path="/onboard/cms/:uuid/edit" />

        <Settings.DirectCss path="/settings/direct-css" />
        <Settings.LoginCss path="/settings/login-css" />
        <Settings.ConversionSafeguard path="/settings/conversion-safeguard" />

        <ContractRules.List path="/rules" />
        <ContractRules.Show path="/rules/:uuid" />
        <ContractRules.New path="/rules/new" />
        <ContractRules.Edit path="/rules/:uuid/edit" />

        <ConversionRules.List path="/attributions" />
        <ConversionRules.Show path="/attributions/:uuid" />
        <ConversionRules.New path="/attributions/new" />
        <ConversionRules.Edit path="/attributions/:uuid/edit" />

        <ConversionRuleSets.List path="/attribution_presets" />
        <ConversionRuleSets.Show path="/attribution_presets/:uuid" />
        <ConversionRuleSets.New path="/attribution_presets/new" />
        <ConversionRuleSets.Edit path="/attribution_presets/:uuid/edit" />

        <Callbacks.List path="/conversions" />
        <Callbacks.Show path="/conversions/:uuid" />
        <Callbacks.Edit path="/conversions/:uuid/edit" />

        <CallbackEvents.List path="/conversion_events" />
        <CallbackEvents.Show path="/conversion_events/:uuid" />

        <Invoices.List path="/invoices" />
        <Invoices.Show path="/invoices/:uuid" />

        <GaAccounts.List path="/ga_accounts" />
        <GaAccounts.Show path="/ga_accounts/:uuid" />
        <GaAccounts.New path="/ga_accounts/new" />
        <GaAccounts.Edit path="/ga_accounts/:uuid/edit" />

        <GaAttributions.List path="/ga_attributions" />
        <GaAttributions.Show path="/ga_attributions/:uuid" />
        <GaAttributions.New path="/ga_attributions/new" />
        <GaAttributions.Edit path="/ga_attributions/:uuid/edit" />

        <ContractsEnded path="/tools/contracts-ended" />
        <OutboundChecker path="/tools/outbound-checker" />
        <QueueChecker path="/tools/queue-checker" />
      </Router>
    </Layout>
  )
}
