import React, { useState } from 'react'
import {
  Button as MaterialButton,
  Paper,
  Grid,
  TextField,
} from '@material-ui/core'
import { isBlank } from '../../services/utils'

export const useFilterState = () => {
  const [filter, setFilter] = useState({})

  const submit = (values) => {
    const { domain, name, tuneId } = values

    const newFilter = {}
    if (domain) newFilter.domain = domain
    if (name) newFilter.name = name
    if (tuneId) newFilter.tuneId = tuneId

    setFilter(newFilter)
  }

  const clear = () => {
    setFilter({})
  }

  return {
    filter,
    submit,
    clear,
  }
}

export const Filter = (props) => {
  const { className, state } = props

  const [dirty, setDirty] = useState(false)

  const [domain, setDomain] = useState('')
  const [name, setName] = useState('')
  const [tuneId, setTuneId] = useState('')

  const submit = () => {
    setDirty(false)
    state.submit({
      domain,
      name,
      tuneId,
    })
  }

  const clear = () => {
    setDomain('')
    setName('')
    setTuneId('')
    setDirty(false)
    state.clear()
  }

  return (
    <Paper
      className={className}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          submit()
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <TextField
            label="Domain"
            value={domain}
            onChange={(event) => {
              setDirty(true)
              setDomain(event.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Name"
            value={name}
            onChange={(event) => {
              setDirty(true)
              setName(event.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Tune ID"
            value={tuneId}
            onChange={(event) => {
              setDirty(true)
              setTuneId(event.target.value)
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className="actions">
        <Grid item>
          {!isBlank(state.filter) && (
            <MaterialButton color="secondary" onClick={clear}>
              Clear
            </MaterialButton>
          )}
        </Grid>
        <Grid item>
          <MaterialButton
            color="primary"
            variant="contained"
            disabled={!dirty}
            onClick={submit}
          >
            Filter
          </MaterialButton>
        </Grid>
      </Grid>
    </Paper>
  )
}
